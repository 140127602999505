import { render, staticRenderFns } from "./courseTagsList.vue?vue&type=template&id=11a3afbb&scoped=true"
import script from "./courseTagsList.vue?vue&type=script&lang=js"
export * from "./courseTagsList.vue?vue&type=script&lang=js"
import style0 from "./courseTagsList.vue?vue&type=style&index=0&id=11a3afbb&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a3afbb",
  null
  
)

export default component.exports